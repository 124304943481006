import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../../flash/flash-service";
import {WorkflowService} from "../../workflow/workflow-service";

@inject(
    Client,
    DialogService,
    FlashService,
    WorkflowService
)
@customElement('sio-ship-vacancy')
export class ShipVacancy {

    client;
    dialogService;
    flash;
    form;

    constructor(client, dialogService, flash, workflowService) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
    }

    submit() {

        let data = this.form.formService.getValue();
        this._isSubmitting = true;

        this.data = null;

        return this.client.post('tourism-ship/cabine-aggregation', data).then(data => {

            this.cabines = data.data.cabines;
            this.orderItems = data.data.orderItems;
            this.fromDate = data.data.fromDate;
            this.duration = data.data.duration;

            this._isSubmitting = false;

            return data;
        }).catch(error => {
            this._isSubmitting = false;

            throw error;
        });
    }
}
