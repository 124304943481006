import React, {useRef} from "react";
import FullCalendar from "@fullcalendar/react";
import {CALENDAR_LICENSE_KEY} from "../../order/offer-configuration/constants";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from "@fullcalendar/moment";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceTimeGridDayPlugin from "@fullcalendar/resource-daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";

export const VacancyDisplay = ({orderItems, cabines, fromDate, duration}) => {

    const calendarRef = useRef();

    return (
        <div>
            <FullCalendar
                stickyHeaderDates={true}
                initialView={"test"}
                schedulerLicenseKey={CALENDAR_LICENSE_KEY}
                plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    resourceTimeGridPlugin,
                    resourceTimeGridDayPlugin,
                    interactionPlugin,
                    momentPlugin,
                    resourceTimelinePlugin,
                ]}
                ref={calendarRef}
                initialDate={fromDate}
                headerToolbar={{
                    left: "",
                    center: "",
                    right: "",
                }}
                slotLabelFormat={[
                    {month: 'long', year: 'numeric'}, // top level of text
                    {day: '2-digit'} // lower level of text
                ]}
                views={{
                    test: {
                        type: "resourceTimelineYear",
                        duration: {days: duration},
                        dateIncrement: {days: 1}
                    },
                }}
                slotDuration={'24:00:00'}
                locale={'de'}
                contentHeight={"auto"}
                windowResize={() => {}}
                resourceGroupField={'grouping'}
                events={orderItems}
                resources={cabines}
            />
        </div>
    );

};

export default VacancyDisplay;
