import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../api/client";
import * as _ from "lodash";
import Sortable from "sortablejs";
import {I18N} from "aurelia-i18n";
import {FlashService} from "../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";
import {BindingSignaler} from "aurelia-templating-resources";
import {DialogService} from "aurelia-dialog";

import "./room-manager.less";

@customElement('sio-tourism-room-manager')
@inject(Client, I18N, FlashService, EventAggregator, BindingSignaler, DialogService)
export class RoomManager {
    @bindable
    journey;

    viewReady = false;
    sortingInitialized = false;

    choices = [];

    constructor(client, i18n, flash, ea, signaler, dialogService) {
        this.client = client;
        this.i18n = i18n;
        this.flash = flash;
        this.ea = ea;
        this.signaler = signaler;
        this.dialogService = dialogService;
    }

    setChanged(changed) {
        if (changed) {
            if (null != this.changesKey) {
                return; // No need to notify several times
            }

            this.changesKey = 'tourism/room-manager__' + new Date();
            this.ea.publish('sio_register_unsaved_changes', {changesKey: this.changesKey});
        } else {

            this.ea.publish('sio_unregister_unsaved_changes', {changesKey: this.changesKey});
            this.changesKey = null;
        }
    }

    journeyChanged() {

        let conditions = {
            includeArchived: true,
            journey: {
                $eq: {
                    id: this.journey.id,
                    modelId: this.journey.modelId
                }
            }
        };

        this.client
            .get('tourism-journey/occupancy-choice?conditions=' + JSON.stringify(conditions) + '&embeds[]=assignment.participants&embeds[]=occupancyType')
            .then(data => {
                console.log('Occupancy choices', data);

                this.processChoices(data.items);
                this.sortingInitialized = false;

                setTimeout(() => {
                    this._initializeSorting();
                }, 1000);
            });
    }

    processChoices(choices) {
        choices.forEach((choice) => {

            if (choice.contingent == null) {
                return;
            }

            for (let i = choice.assignment.length; i < choice.contingent; i++) {
                choice.assignment.push({participants: []});
            }
        });

        this.choices = choices;
    }

    attached() {
        this.viewReady = true;

        this._initializeSorting();

        this.subscriber = this.ea.subscribe('sio_form_post_submit', response => {

            if (response.config.modelId !== this.journey.modelId) {
                return;
            }

            this.journeyChanged();
        });
    }

    detached() {
        this.subscriber.dispose();
        this.setChanged(false);
        this.viewReady = false;
    }

    addRoom(choice) {
        choice.assignment.push({participants: []});

        setTimeout(() => {
            this._initializeRoom('choice-' + choice.id + '-' + (choice.assignment.length - 1));
        }, 0);
    }

    _getGroupedCount(choice) {
        let count = 0;
        let max = 0;

        _.each(choice.assignment, assignment => {
            count += assignment.participants.length;
        });

        let string = count;

        if (choice.contingent != null) {
            let max = choice.occupancyType.max * choice.contingent;

            string += ' / ' + max;
        } else {
            string += ' / ∞';
        }

        return string;
    }

    _choiceForIdentifier(identifier) {
        //choice-choiceID-index
        let parts = identifier.split('-');

        let choice = _.find(this.choices, (choice) => {
            return choice.id === parts[1];
        });

        return {
            choice: choice,
            room: choice.assignment[parts[2]]
        };
    }

    _initializeSorting() {
        if (!this.viewReady || this.choices.length == 0 || this.sortingInitialized) {
            return;
        }

        this.sortingInitialized = true;

        this.choices.forEach((choice) => {

            choice.assignment.forEach((assignment, index) => {
                this._initializeRoom('choice-' + choice.id + '-' + index);
            });

        });
    }

    _initializeRoom(identifier) {

        console.log('Initialize sorting' + identifier);

        Sortable.create(document.getElementById(identifier), {
            group: 'room',
            forceFallback: true,
            onMove: (evt) => {

                if (evt.from.id !== evt.to.id) {

                    const to = this._choiceForIdentifier(evt.to.id);

                    if (to.choice.occupancyType.max <= to.room.participants.length) {
                        return false;
                    }

                    const from = this._choiceForIdentifier(evt.from.id);

                    if (to.choice.occupancyType !== from.choice.occupancyType) {
                        return false;
                    }
                }
            },
            onSort: (evt) => {

                const from = this._choiceForIdentifier(evt.from.id);
                const to = this._choiceForIdentifier(evt.to.id);
                const fromIndex = _.findIndex(from.room.participants, participant => evt.item.dataset.value === participant.id);

                if (fromIndex === -1) {
                    return;
                }

                const participant = from.room.participants[fromIndex];

                from.room.participants.splice(fromIndex, 1);

                to.room.participants.push(participant);

                this.signaler.signal('moved');
                this.setChanged(true);
            }
        });
    }

    save() {

        let choices = [];

        // yes start on 1 to skip "_no", as this is always first entry
        for (let i = 0; i < this.choices.length; i++) {
            let choice = this.choices[i];
            let assignments = [];

            for (let j = 0; j < choice.assignment.length; j++) {
                let assignment = choice.assignment[j];

                assignments.push({
                    participants: assignment.participants.map(item => {
                        return {
                            id: item.id,
                            modelId: item.modelId
                        };
                    })
                });
            }

            choices.push({
                choice: {
                    id: choice.id,
                    modelId: choice.modelId
                },
                assignments: assignments
            });
        }

        return this.client.put('tourism/journey/update-assignments/' + this.journey.id, {choices: choices}).then(data => {

            this.ea.publish('sio_form_post_submit', {config: {modelId: 'tourism/journey'}});

            this.flash.success('form.success');
            this.setChanged(false);

            return data;
        });
    }
}
