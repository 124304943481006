import {bindable, customElement, inject, noView} from "aurelia-framework";
import {render, unmountComponentAtNode} from "react-dom";
import React from "react";
import VacancyDisplay from "./vacancy-display";
import {Client} from "../../api/client";

@noView
@inject(Element, Client)
@customElement("vacancy-display-aurelia")
export default class VacancyDisplayAurelia {
    @bindable() cabines;
    @bindable() orderItems;
    @bindable() fromDate;
    @bindable() duration;

    client = null;

    constructor(container, client) {
        this.container = container;
        this.client = client;
    }

    attached() {
        this.render();
    }

    orderItemsChanged() {
        this.detached();

        this.attached();
    }

    cabinesChanged() {
        this.detached();

        this.attached();
    }

    fromDateChanged() {
        this.detached();

        this.attached();
    }

    durationChanged() {
        this.detached();

        this.attached();
    }

    async render() {
        render(<VacancyDisplay
            orderItems={this.orderItems}
            cabines={this.cabines}
            fromDate={this.fromDate}
            duration={this.duration}/>, this.container);
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}
